import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ProblemScapeGameHeader from "~/components/ProblemScapeGameHeader";
import Seo from "~/components/Seo";

function ProblemScapeCh1Page() {
  return (
    <>
      <ProblemScapeGameHeader />
      <section
        id="chapter-1-overview"
        className="Index-page"
        data-collection-id="60635699b0e7853338ab2987"
        data-edit-main-image="Background"
      >
        <div
          className="Index-page-content "
          id="yui_3_17_2_1_1671304180297_789"
        >
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-60635699b0e7853338ab2987"
          >
            <div className="row sqs-row" id="yui_3_17_2_1_1671304180297_788">
              <div
                className="col sqs-col-12"
                id="yui_3_17_2_1_1671304180297_787"
              >
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-07f2bb2e4a1b157a83a4"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      CHAPTER 1 OVERVIEW: <br />
                      MYSTERY OF THE MISSING MAYOR
                    </h2>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_786"
                >
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_785"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-33f28e49e40cc45497d6"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_784"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_783"
                        >
                          <StaticImage
                            src="../../images/problemscape/ch1demoipad-19.png"
                            alt="Demo"
                            objectFit="cover"
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_810"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="74.671669793621"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1658170987001_8828"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_809"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_808"
                        >
                          <StaticImage
                            src="../../images/standards/chapter-1.png"
                            alt="Chapter 1"
                            height={400}
                            width={533}
                            objectFit="cover"
                            transformOptions={{
                              fit: "cover",
                              cropFocus: "center",
                            }}
                            quality={95}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-16fe264008176f017a25"
                >
                  <div className="sqs-block-content">
                    <h3 style={{ whiteSpace: "pre-wrap" }}>
                      <strong>where is the mayor of arithma? </strong>
                    </h3>
                    <ul data-rte-list="default">
                      <li>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          The adventure starts with the player going to the
                          nation of Arithma in search of their sibling. They
                          need to talk to the mayor to get more information, but
                          the mayor is not easy to find. First, the player has
                          to play a game of paintball and solve a jigsaw puzzle
                          for Jinga, an Arithman. Jinga will become the player’s
                          assistant and take them to the mayor’s house. Can they
                          unlock the mayor’s gate?
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_830"
                >
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_829"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1617122982927_15983"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_828"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_827"
                        >
                          <StaticImage
                            src="../../images/problemscape/jigsaw.png"
                            alt="Jigsaw"
                            objectFit="cover"
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_849"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1617122982927_22452"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_848"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_847"
                        >
                          <StaticImage
                            src="../../images/problemscape/lock.png"
                            alt="Lock"
                            objectFit="cover"
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-yui_3_17_2_1_1617122982927_23985"
                >
                  <div className="sqs-block-content">
                    <h3 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      <strong>chapter Highlight</strong>
                    </h3>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_869"
                >
                  <div className="col sqs-col-6 span-6">
                    <div
                      className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                      data-block-type="21"
                      id="block-yui_3_17_2_1_1658163350226_70531"
                    >
                      <div className="sqs-block-content">&nbsp;</div>
                    </div>
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-yui_3_17_2_1_1617122982927_30190"
                    >
                      <div className="sqs-block-content">
                        <h3 style={{ whiteSpace: "pre-wrap" }}>
                          <strong>Teach Jinga the Order of Operations</strong>
                        </h3>
                        <ul data-rte-list="default">
                          <li>
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              It is not enough if the player evaluates the
                              expressions using the order of operations to
                              unlock the mayor’s gate! They also have to teach
                              the order of operations to their assistant, Jinga!
                              Teaching others helps to eliminate misconceptions
                              like ‘multiplication before division’ and
                              ‘addition before subtraction’ that are very common
                              in students’ understanding of the order of
                              operations.{" "}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_868"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1617122982927_28884"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_867"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_866"
                        >
                          <StaticImage
                            src="../../images/problemscape/teachpad.png"
                            alt="Jigsaw"
                            objectFit="cover"
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export function Head() {
  return <Seo title="Ch 1 Overview" />;
}

export default ProblemScapeCh1Page;
